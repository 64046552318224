import React from 'react';
import { CheckCircle } from '@phosphor-icons/react';
import Button from '../../elements/Button';
import FeaturesVpn from '../../../assets/images/features-vpn.png';
import FeaturesControlPanel from '../../../assets/images/features-control-panel.png';
import FeaturesRestWebhook from '../../../assets/images/features-rest-webhook.png';
import FeaturesSupport from '../../../assets/images/features-support.png';

function Features() {
  return (
    <section className="features py-9 py-lg-20" id="features">
      <div className="container">
        <div className="row mb-12">
          <div className="col col-12 text-center">
            <div className="position-relative">
              <h1 className="position-relative z-plus-3">
                Our
                {' '}
                <span className="clr-primary">Features</span>
              </h1>
              <p className="font-size-lg position-relative z-plus-3 mb-6 mb-md-16 px-0 px-lg-20">
                Discover how our Hostry VPN PaaS transforms the landscape of
                digital security and network management.
              </p>
            </div>
          </div>
        </div>

        <div className="row mb-18 mb-md-10">
          <div className="col col-xs-5 col-md-6 order-1 d-none d-xs-block">
            <img
              src={FeaturesVpn}
              alt=""
              className="no-select w-100"
            />
          </div>
          <div className="d-flex flex-column justify-content-center align-items-start col col-xs-7 col-md-6 order-2 px-lg-15 pb-lg-10">
            <div className="badge">
              PaaS Platform
            </div>
            <h2>
              Comprehensive VPN PaaS Platform
            </h2>
            <p>
              Hostry VPN PaaS is your all-encompassing toolkit for creating robust
              and scalable VPN and Proxy solutions.
            </p>
            <p>
              Tailored to support a broad spectrum of applications, from mobile VPN apps
              to extensive corporate networks, our platform not only delivers exceptional
              performance and security but also offers unlimited scalability and
              customization to meet your unique business needs.
            </p>
            <Button
              as="a"
              href={process.env.REACT_APP_REGISTRATION}
              variant="primary"
              size="lg"
              className="mt-4 mt-md-9"
            >
              Try for Free
            </Button>
          </div>
        </div>

        <div className="row mb-18 mb-md-10">
          <div className="col col-xs-5 col-md-6 order-2 d-none d-xs-block">
            <img
              src={FeaturesControlPanel}
              alt=""
              className="no-select w-100"
            />
          </div>
          <div className="d-flex flex-column justify-content-center align-items-start col col-xs-7 col-md-6 order-1 px-lg-15 pb-lg-10">
            <div className="badge">
              Control Panel
            </div>
            <h2>
              Intuitive VPN Control Panel
            </h2>
            <p>
              Hostry VPN Control Panel is more than just management;
              {/* eslint-disable-next-line */}
              it's a comprehensive control center for your VPN service.
            </p>
            <p>
              Manage servers, configure VPN protocols, segment networks, create rules,
              user groups, experiment with pricing, and apply filters—all within a
              user-friendly and intuitive interface. Our powerful suite of tools allows
              for flexible service customization, ensuring the highest level of security
            </p>
            <Button
              as="a"
              href={process.env.REACT_APP_REGISTRATION}
              variant="primary"
              size="lg"
              className="mt-4 mt-md-9"
            >
              Try for Free
            </Button>
          </div>
        </div>

        <div className="row mb-18 mb-md-10">
          <div className="col col-xs-5 col-md-6 order-1 d-none d-xs-block">
            <img
              src={FeaturesRestWebhook}
              alt=""
              className="no-select w-100"
            />
          </div>
          <div className="d-flex flex-column justify-content-center align-items-start col col-xs-7 col-md-6 order-2 px-lg-15 pb-lg-10">
            <div className="badge">
              Integrations
            </div>
            <h2>
              REST API and Webhook Integration
            </h2>
            <p>
              With a powerful REST API and Webhook integration, Hostry VPN PaaS provides
              developers with flexible tools for creating high-quality applications,
              simplifying management and automation, and ensuring immediate
              notification of important events.
            </p>
            <Button
              as="a"
              href={process.env.REACT_APP_REGISTRATION}
              variant="primary"
              size="lg"
              className="mt-4 mt-md-9"
            >
              Try for Free
            </Button>
          </div>
        </div>

        <div className="row mb-18 mb-md-10">
          <div className="col col-xs-5 col-md-6 order-2 d-none d-xs-block">
            <img
              src={FeaturesSupport}
              alt=""
              className="no-select w-100"
            />
          </div>
          <div className="d-flex flex-column justify-content-center align-items-start col col-xs-7 col-md-6 order-1 px-lg-15 pb-lg-10">
            <div className="badge">
              Protocols
            </div>
            <h2>
              Broad Support for VPN Protocols
            </h2>
            <p>
              Hostry VPN PaaS offers support for leading VPN protocols,
              including OpenVPN, WireGuard, v2fly, and Xray.
            </p>
            <p>
              This not only ensures a high level of security and performance for your
              network but also provides flexible solutions for circumventing censorship
              and filtering in various countries. Our platform effectively addresses
              access issues, allowing users to freely and securely use the internet worldwide.
            </p>
            <Button
              as="a"
              href={process.env.REACT_APP_REGISTRATION}
              variant="primary"
              size="lg"
              className="mt-4 mt-md-9"
            >
              Try for Free
            </Button>
          </div>
        </div>

        <div className="row">
          <div className="col col-xs-5 col-md-6 order-1 d-none d-xs-block">
            <img
              src={FeaturesRestWebhook}
              alt=""
              className="no-select w-100"
            />
          </div>
          <div className="d-flex flex-column justify-content-center align-items-start col col-xs-7 col-md-6 order-2 px-lg-15 pb-lg-10">
            <div className="badge">
              Libraries
            </div>
            <h2>
              Deep Integration with External Services
            </h2>
            <p>
              We offer extensive opportunities for integration with popular external
              services and software, enriching the functionality of your VPN solution
              and providing additional capabilities for your users:
            </p>
            <ul className="list-unstyled m-0 p-0">
              <li className="mb-2 d-flex">
                <CheckCircle className="mr-2 fill-success icon-size-xl" size="24" />
                <span>Firebase: For mobile VPN app development</span>
              </li>
              <li className="mb-2 d-flex">
                <CheckCircle className="mr-2 fill-success icon-size-xl" size="24" />
                <span>Revenue Cat: A subscription platform built for mobile apps</span>
              </li>
              <li className="mb-2 d-flex">
                <CheckCircle className="mr-2 fill-success icon-size-xl" size="24" />
                <span>
                  PlayIntegrity & DeviceCheck: Ensures a secure and hack-resistant
                  system for utilizing Free accounts
                </span>
              </li>
              <li className="mb-2 d-flex">
                <CheckCircle className="mr-2 fill-success icon-size-xl" size="24" />
                <span>
                  Partitions Servers: A smart server rotation system based on selected
                  rules for specific user groups or locations/servers
                </span>
              </li>
              <li className="mb-2 d-flex">
                <CheckCircle className="mr-2 fill-success icon-size-xl" size="24" />
                <span>Geo Limits: Geographic restrictions.</span>
              </li>
              <li className="mb-2 d-flex">
                <CheckCircle className="mr-2 fill-success icon-size-xl" size="24" />
                <span>WHMCS: A web hosting automation platform</span>
              </li>
            </ul>
            <Button
              as="a"
              href={process.env.REACT_APP_REGISTRATION}
              variant="primary"
              size="lg"
              className="mt-4 mt-md-9"
            >
              Try for Free
            </Button>
          </div>
        </div>

      </div>
    </section>
  );
}

export default Features;
